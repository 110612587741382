import React from 'react';
import './CalendarioPage.css'; // Asegúrate de que este archivo CSS exista y contenga los estilos necesarios.

// Importa las imágenes usando la ruta correcta
import img1 from '../images/aZ3K3yKVew-a01.png';
import img2 from '../images/P8Q5cVeQfl-a02.png';
import img3 from '../images/iD2s26k83H-a03.png';
import img4 from '../images/4SqLTSlM47-a04.jpg';
import img5 from '../images/AJcMcOIYqi-b01.png';
import img6 from '../images/bBzSTOh6ds-b02.png';
import img7 from '../images/fwVODn3sn3-b03.png';
import img8 from '../images/SYInG7CZcn-b04.png';
import img9 from '../images/BUYeKOJfHT-b05.png';
import img10 from '../images/6W8kHQSMmA-b06.png';
import img11 from '../images/b2M9BcZHvA-c01.png';
import img12 from '../images/MSI0EAPJ8X-c02.png';
import img13 from '../images/uhCZAVjGY3-c03.png';
import img14 from '../images/PHAH9cYP9j-c04.png';
import img15 from '../images/zyom4p2rfh-c05.png';
import img16 from '../images/Jq3gsr9nVq-c06.png';
import img17 from '../images/3VAStt8LfM-anotaciones.jpg';
import img18 from '../images/r3gXTpqpIS-d02.png';
import img19 from '../images/2e7AA6E7ro-f01.png';

const CalendarioPage = () => {
    return (
        <div style={{ backgroundColor: 'white' }}>
            <main className="calendario-container">
                <div className="container-fluid header-mg">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-sm-8 col-sm-offset-2">
                                    <img className="img-responsive" src={img1} alt="Calendario 2024-2025" />
                                </div>
                            </div>
                            <div className="row hidden-xs">
                                <br />
                                <div className="col-sm-12">
                                    <img className="img-responsive" src={img2} alt="Calendario" />
                                </div>
                                <br />
                            </div>
                            <div className="col-xs-10 col-xs-offset-1 visible-xs">
                                <br />
                                <span style={{ color: '#a22244', fontWeight: 'bold', letterSpacing: '.3em' }}>
                                    EDUCACIÓN PREESCOLAR, PRIMARIA Y SECUNDARIA
                                </span>
                                <h1 style={{ color: '#691b31', fontWeight: 'bold' }}>
                                    Calendario Escolar <span style={{ color: '#bc955a' }}>2024-2025</span>
                                </h1>
                                <p className="small">
                                    Vigente para las escuelas públicas y particulares incorporadas al Sistema Educativo Nacional en los Estados Unidos Mexicanos.
                                </p>
                                <br />
                            </div>
                            <br />
                            <img className="img-responsive" src={img3} alt="Imagen adicional" />
                        </div>
                        <div className="col-sm-4 hidden-sm hidden-xs">
                            <img className="img-responsive" src={img4} alt="SEP" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-2">
                            <img className="img-responsive" src={img5} alt="Calendario 1" />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-2">
                            <img className="img-responsive" src={img6} alt="Calendario 2" />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-2">
                            <img className="img-responsive" src={img7} alt="Calendario 3" />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-2">
                            <img className="img-responsive" src={img8} alt="Calendario 4" />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-2">
                            <img className="img-responsive" src={img9} alt="Calendario 5" />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-2">
                            <img className="img-responsive" src={img10} alt="Calendario 6" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-2">
                            <img className="img-responsive" src={img11} alt="Calendario 7" />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-2">
                            <img className="img-responsive" src={img12} alt="Calendario 8" />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-2">
                            <img className="img-responsive" src={img13} alt="Calendario 9" />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-2">
                            <img className="img-responsive" src={img14} alt="Calendario 10" />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-2">
                            <img className="img-responsive" src={img15} alt="Calendario 11" />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-2">
                            <img className="img-responsive" src={img16} alt="Calendario 12" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-8">
                            <br /><br />
                            <img className="img-responsive" src={img17} alt="Anotaciones" />
                        </div>
                        <div className="col-sm-4">
                            <img className="img-responsive" src={img18} alt="Imagen adicional" />
                        </div>
                    </div>
                    <img className="img-responsive" src={img19} alt="Imagen final" />
                </div>
            </main>
        </div>
    );
};

export default CalendarioPage;
