import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const EncuestaPage = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadGoogleAuth = () => {
            const script = document.createElement('script');
            script.src = 'https://accounts.google.com/gsi/client';
            script.onload = () => {
                window.google.accounts.id.initialize({
                    client_id: '627784649782-j909s0j3o56hkqn63m76dc7mbn9vfg3k.apps.googleusercontent.com',
                    callback: handleCallbackResponse
                });
                window.google.accounts.id.prompt((notification) => {
                    if (notification.isAuthenticated) {
                        setIsAuthenticated(true);
                    } else {
                        setLoading(false);
                    }
                });
            };
            document.body.appendChild(script);
        };

        loadGoogleAuth();
    }, []);

    const handleCallbackResponse = (response) => {
        if (response.credential) {
            setIsAuthenticated(true);
            // Redirigir automáticamente al formulario de Google
            window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLScnEEWsNXU8e1EMPAGzDngXLojpvZ0Qj8BYvLzR7f1QrbIBRw/viewform';
        }
    };

    const handleSignIn = () => {
        window.google.accounts.id.prompt(); // Muestra el cuadro de diálogo de inicio de sesión
    };

    return (
        <div className="form-container text-center">
            {loading ? (
                <div>Cargando…</div>
            ) : isAuthenticated ? (
                <div>
                    {/* Este mensaje no será visible debido a la redirección automática */}
                    <h6>Redirigiendo al formulario...</h6>
                </div>
            ) : (
                <div className="alert alert-info d-flex flex-column align-items-center" style={{ padding: '10px', borderRadius: '5px' }}>
                    <h6 className="mb-2">¡Hola! Para ver la encuesta, por favor inicia sesión con tu cuenta de Google en el mensaje que verás.</h6>
                    <button
                        className="btn btn-danger btn-sm" // Cambia el tamaño del botón
                        onClick={handleSignIn}
                    >
                        Elegir cuenta de Google
                    </button>
                </div>
            )}
        </div>
    );
};

export default EncuestaPage;
