import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google'; // Importar GoogleOAuthProvider
import Navbar from './components/Navbar';
import Slider from './components/Slider';
import NewsSection from './components/NewsSection';
import Sections from './components/Sections';
import Teachers from './components/Teachers';
import Contact from './components/Contact';
import DownloadPage from './components/DownloadPage';
import EncuestaPage from './components/EncuestaPage';
import EncuestaDocentePage from './components/EncuestaDocentePage';
import CalendarioPage from './components/CalendarioPage';
import CocinaPage from './components/CocinaPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import './App.css';
import sepIcon from './images/sep.png';

const App = () => (
  <GoogleOAuthProvider clientId="627784649782-j909s0j3o56hkqn63m76dc7mbn9vfg3k.apps.googleusercontent.com"> {/* Tu Client ID aquí */}
    <div style={{ backgroundColor: '#f0f0f0', color: '#333' }}>
      <Navbar />
      <Routes>
        <Route path="/" element={<><Slider /><NewsSection /></>} />
        <Route path="/sections" element={<Sections />} />
        <Route path="/teachers" element={<Teachers />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/sicrer" element={<DownloadPage />} />
        <Route path="/encuesta" element={<EncuestaPage />} />
        <Route path="/edoc" element={<EncuestaDocentePage />} />
        <Route path="/calendario" element={<CalendarioPage />} />
        <Route path="/cocina4b" element={<CocinaPage />} />
        <Route path="/privacidad" element={<PrivacyPolicy />} />
        <Route path="/terminos" element={<TermsAndConditions />} />
      </Routes>
      <footer style={{ textAlign: 'center', padding: '1rem', backgroundColor: '#fff', borderTop: '1px solid #ddd' }}>
        <p style={{ fontSize: '0.9rem', marginBottom: '0.5rem' }}>
          Todos los derechos reservados (2024) ©
        </p>
        <p style={{ fontSize: '0.8rem', margin: 0 }}>
          <a href="https://creativecommons.org/licenses/by-nc-nd/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer" style={{ display: 'inline-block', marginRight: '5px' }}>
            <img style={{ height: '22px', verticalAlign: 'text-bottom' }} src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1" alt="CC" />
            <img style={{ height: '22px', verticalAlign: 'text-bottom' }} src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1" alt="BY" />
            <img style={{ height: '22px', verticalAlign: 'text-bottom' }} src="https://mirrors.creativecommons.org/presskit/icons/nc.svg?ref=chooser-v1" alt="NC" />
            <img style={{ height: '22px', verticalAlign: 'text-bottom' }} src="https://mirrors.creativecommons.org/presskit/icons/nd.svg?ref=chooser-v1" alt="ND" />
          </a>
        </p>
        <a href="https://www.sepbcs.gob.mx/" target="_blank" rel="noopener noreferrer">
          <img src={sepIcon} alt="SEP" style={{ height: '50px', marginTop: '0.5rem' }} />
        </a>
        <p>
          <Link to="/privacidad" className="footer-link">Política de Privacidad </Link>
          |
          <Link to="/terminos" className="footer-link"> Términos y Condiciones</Link>
        </p>
      </footer>
    </div>
  </GoogleOAuthProvider>
);

export default App;
