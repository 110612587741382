import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import './Navbar.css';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <Link to="/">
                    <img src={logo} alt="Esc. Prim. Arturo Guerrero González T.M." style={{ height: '100px', borderRadius: '5px' }} />
                </Link>
            </div>
            <div className="navbar-toggle" onClick={toggleMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
            <div className={`navbar-links ${isOpen ? 'open' : ''}`}>
                <Link to="/" className="navbar-link" onClick={closeMenu}>Inicio</Link>
                <Link to="/sections" className="navbar-link" onClick={closeMenu}>Secciones</Link>
                <Link to="/teachers" className="navbar-link" onClick={closeMenu}>Docentes</Link>
                <Link to="/calendario" className="navbar-link" onClick={closeMenu}>Calendario</Link>
                <Link to="/contact" className="navbar-link" onClick={closeMenu}>Contacto</Link>
            </div>
        </nav>
    );
};

export default Navbar;
