import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy">
            <h2>Política de Privacidad</h2>
            <p>En <strong>Esc. Prim. Arturo Guerrero González T.M.</strong>, valoramos la privacidad de nuestros estudiantes, padres de familia, docentes y todos los visitantes de nuestro sitio web. Esta política de privacidad describe cómo recopilamos, usamos, protegemos y compartimos la información personal que recibimos a través de nuestra página web, que incluye el uso de formularios y la integración con APIs de Google.</p>

            <h3>1. Información que Recopilamos</h3>
            <p>A través de nuestro sitio web, podemos recopilar la siguiente información:</p>
            <ul>
                <li><strong>Información personal:</strong> nombre, dirección de correo electrónico, número de teléfono, y otra información de contacto que se recopila a través de formularios.</li>
                <li><strong>Información de uso:</strong> dirección IP, tipo de dispositivo, navegador y datos sobre cómo interactúa con nuestro sitio web.</li>
                <li><strong>Datos adicionales:</strong> cualquier otra información que se recopile a través de los formularios, como comentarios o solicitudes específicas.</li>
            </ul>

            <h3>2. Uso de la Información</h3>
            <p>La información recopilada será utilizada exclusivamente para los siguientes fines:</p>
            <ul>
                <li>Gestionar solicitudes y consultas a través de los formularios.</li>
                <li>Enviar notificaciones importantes relacionadas con la escuela, eventos o actividades.</li>
                <li>Mejorar la funcionalidad y el contenido de nuestro sitio web.</li>
                <li>Cumplir con requisitos legales o normativos, en caso de ser necesario.</li>
            </ul>

            <h3>3. Integración con Google Forms y APIs de Google</h3>
            <p>Nuestro sitio web utiliza <strong>Google Forms</strong> para gestionar algunas encuestas, inscripciones y formularios de contacto. Además, usamos <strong>APIs de Google</strong> para servicios como Google Maps o Google Calendar.</p>
            <p><strong>Google Forms:</strong> Al enviar información a través de formularios de Google, los datos se almacenan en servidores de Google, sujetos a sus políticas de privacidad.</p>
        </div>
    );
};

export default PrivacyPolicy;
