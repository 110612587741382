import React, { useEffect, useState } from 'react';

const EncuestaPage = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        // Cargar la librería de Google Auth
        window.google.accounts.id.initialize({
            client_id: 'TU_CLIENT_ID', // Reemplaza con tu Client ID de Google
            callback: handleCallbackResponse
        });

        // Si el usuario ya está autenticado, obtén el token
        window.google.accounts.id.prompt();
    }, []);

    const handleCallbackResponse = (response) => {
        if (response.credential) {
            setIsAuthenticated(true);
        }
    };

    return (
        <div className="form-container">
            {isAuthenticated ? (
                <iframe
                    title="Google Form"
                    src="https://docs.google.com/forms/d/e/1FAIpQLScnEEWsNXU8e1EMPAGzDngXLojpvZ0Qj8BYvLzR7f1QrbIBRw/viewform?embedded=true"
                    width="100%"
                    height="800px"
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                >
                    Cargando…
                </iframe>
            ) : (
                <div>Por favor, inicia sesión con tu cuenta de Google para ver la encuesta.</div>
            )}
        </div>
    );
};

export default EncuestaPage;
