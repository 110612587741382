import React from 'react';

const NewsSection = () => {
    const news = []; // Simulación de noticias; reemplázalo con datos reales

    return (
        <div style={{ padding: '1rem' }}>
            {news.length === 0 ? (
                <center><p>Página en construcción</p></center>
            ) : (
                <div>
                    {/* Muestra noticias aquí */}
                </div>
            )}
        </div>
    );
};

export default NewsSection;
