import React from 'react';

const TermsAndConditions = () => {
    return (
        <div className="terms-and-conditions">
            <h2>Términos y Condiciones</h2>
            <p>Bienvenido a la página web de <strong>Esc. Prim. Arturo Guerrero González T.M.</strong>. Al acceder y utilizar nuestro sitio, aceptas cumplir con los siguientes términos y condiciones. Si no estás de acuerdo con alguno de estos términos, te recomendamos no utilizar el sitio.</p>

            <h3>1. Uso del Sitio Web</h3>
            <p>El contenido y los materiales presentados en este sitio son solo para uso educativo y de información general. Nos reservamos el derecho de modificar o eliminar cualquier contenido sin previo aviso.</p>

            <h3>2. Propiedad Intelectual</h3>
            <p>Todos los textos, imágenes, gráficos y logotipos presentes en este sitio son propiedad de la escuela o de terceros que han otorgado los derechos de uso. Está prohibida la reproducción total o parcial de cualquier contenido sin autorización previa.</p>

            <h3>3. Responsabilidad</h3>
            <p>No somos responsables de cualquier daño o perjuicio que pueda derivarse del uso de nuestro sitio o de la imposibilidad de acceder al mismo.</p>

            <h3>4. Modificaciones de los Términos</h3>
            <p>Nos reservamos el derecho de actualizar estos términos en cualquier momento. Cualquier cambio será reflejado en esta página.</p>
        </div>
    );
};

export default TermsAndConditions;
