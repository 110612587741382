import React from 'react';

const EncuestaPage = () => {
    return (
        <div className="form-container">
            <iframe
                title="Google Form"
                src="https://docs.google.com/forms/d/e/1FAIpQLSeuCbCihxxWG1cZ3scK93rUhrRe0ZnQLkRdYqowKeqYpQh40g/viewform?embedded=true"
                width="100%"
                height="800px"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
            >
                Cargando…
            </iframe>
        </div>
    );
};

export default EncuestaPage;